body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/*Start of scroll bar styling*/
::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background: #999;
    border-radius: 4px;
    border: 2px solid transparent;
    transition: background-color 0.2s ease-in-out;
}

::-webkit-scrollbar-thumb:hover {
    background: #777;
}

/*End of scroll bar styling*/